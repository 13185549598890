html, body, #app, #auth-container, #admin-container {
  height: 100%;
}

.ant-calendar-footer-btn {
  display: none;
}

#admin-container {
  display: flex;
  flex-direction: column;
}

#content-container {
  flex:1;
  display: flex;
}

body {
  background:  #000c17;
}

.ant-table-row { 
    background: #fff;
    border-bottom: 3px solid lightgray;
}

.fade-wait-leave {
    opacity: 1;
  }
  .fade-wait-leave.fade-wait-leave-active {
    opacity: 0;
    transition: opacity .4s ease-in;
  }
  
  .fade-wait-enter {
    opacity: 0;
  }
  .fade-wait-enter.fade-wait-enter-active {
    opacity: 1;
    /* Delay the enter animation until the leave completes */
    transition: opacity .4s ease-in .6s;
  }
  
  .fade-wait-height {
    transition: height .6s ease-in-out;
  }